<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>Crea indisponiiblità</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-back-button></ion-back-button>
          </ion-buttons>
          <ion-title size="large">Nuova indisponiiblità</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-grid>
        <form @submit.prevent="creaIndisponibilita()">
          <ion-list>
            <ion-row>
              <ion-col size="12">
                <ion-item>
                  <ion-label>
                    Data inizio
                    <ion-text color="danger"><strong>*</strong></ion-text>
                  </ion-label>
                  <ion-datetime
                    name="date_start"
                    display-format="DD/MM/YYYY"
                    picker-format="DD/MM/YYYY"
                    min="2021"
                    max="2099"
                    slot="end"
                    doneText="OK"
                    cancelText="Annulla"
                    v-model="indisponibilita.data_inizio"
                  ></ion-datetime>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col size="12">
                <ion-item>
                  <ion-label>
                    Data fine
                    <ion-text color="danger"><strong>*</strong></ion-text>
                  </ion-label>
                  <ion-datetime
                    name="date_end"
                    display-format="DD/MM/YYYY"
                    picker-format="DD/MM/YYYY"
                    min="2021"
                    max="2099"
                    slot="end"
                    doneText="OK"
                    cancelText="Annulla"
                    v-model="indisponibilita.data_fine"
                  ></ion-datetime>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col>
                <ion-item>
                  <div class="field">
                    <div class="field_label">
                      <ion-label>
                        Tipo
                        <ion-text color="danger"><strong>*</strong></ion-text>
                      </ion-label>
                    </div>
                    <div class="field_content">
                      <ion-select
                        cancelText="Annulla"
                        v-model="indisponibilita.tipo"
                      >
                        <ion-select-option value="1">
                          Disponibile
                        </ion-select-option>
                        <ion-select-option value="2">
                          Indisponibile
                        </ion-select-option>
                      </ion-select>
                    </div>
                  </div>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col>
                <ion-item>
                  <div class="field">
                    <div class="field_label">
                      <ion-label>
                        Turni
                        <ion-text color="danger"><strong>*</strong></ion-text>
                      </ion-label>
                    </div>
                    <div class="field_content">
                      <ion-select
                        cancelText="Annulla"
                        multiple="true"
                        v-model="indisponibilita.turni"
                      >
                        <ion-select-option value="1">
                          Mattina
                        </ion-select-option>
                        <ion-select-option value="2">
                          Pomeriggio
                        </ion-select-option>
                        <ion-select-option value="3"> Notte </ion-select-option>
                      </ion-select>
                    </div>
                  </div>
                </ion-item>
              </ion-col>
            </ion-row>

            <!--<ion-row>
              <ion-col size="12">
                <ion-item>
                  <ion-label>
                    Dalle ore
                    <ion-text color="danger"><strong>*</strong></ion-text>
                  </ion-label>
                  <ion-datetime
                    name="start_time"
                    display-format="HH:mm"
                    picker-format="HH:mm"
                    slot="end"
                    doneText="OK"
                    cancelText="Annulla"
                    v-model="indisponibilita.ora_inizio"
                  ></ion-datetime>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col size="12">
                <ion-item>
                  <ion-label>
                    Alle ore
                    <ion-text color="danger"><strong>*</strong></ion-text>
                  </ion-label>
                  <ion-datetime
                    name="end_time"
                    display-format="HH:mm"
                    picker-format="HH:mm"
                    slot="end"
                    doneText="OK"
                    cancelText="Annulla"
                    v-model="indisponibilita.ora_fine"
                  ></ion-datetime>
                </ion-item>
              </ion-col>
            </ion-row> -->

            <ion-row>
              <ion-col size="6">
                <ion-button
                  color="danger"
                  size="default"
                  expand="block"
                  @click="closeModal"
                >
                  Chiudi
                </ion-button>
              </ion-col>
              <ion-col size="6">
                <ion-button
                  color="success"
                  size="default"
                  type="submit"
                  expand="block"
                >
                  Invia
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-list>
        </form>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonButton,
  modalController,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  //IonTextarea,
  IonSelect,
  IonSelectOption,
  IonText,
} from "@ionic/vue";
import { defineComponent, ref, reactive } from "vue";

//import axios from "axios";
import moment from "moment";

import { openToast } from "../services/toast";

import apiIndisponibilita from "../services/indisponibilita";

import { useRouter } from "vue-router";

export default defineComponent({
  name: "ModalIndisponibilita",
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonBackButton,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonLabel,
    IonDatetime,
    //IonTextarea,
    IonSelect,
    IonSelectOption,
    IonText,
  },
  setup(props, context) {
    const router = useRouter();

    const start_date = new Date();
    const end_hour = new Date();
    const start_hour = new Date(end_hour);
    start_hour.setHours(start_hour.getHours() - 1);

    const userID = JSON.parse(localStorage.getItem("userInfo")).users_id; //set userID in user field
    const indisponibilita = reactive({
      user: "", //from logged user
      data_inizio: start_date.toISOString(),
      data_fine: start_date.toISOString(),
      /*       ora_inizio: start_hour.toISOString(),
      ora_fine: end_hour.toISOString(), */
      tipo: "",
      turni: [],
    });

    const customersDB = ref([]);
    const successResponse = ref(false);

    const validDate = ref(false);
    const validHour = ref(false);

    /**
     * Close modal after form data submit
     */
    async function closeModalOnSubmit(successResponse, res) {
      if (successResponse.value) {
        await modalController.dismiss(res);
      } else {
        await modalController.dismiss();
      }
    }

    /**
     * Handle close modal by clicking on Chiudi button
     */
    const closeModal = () => {
      modalController.dismiss();
    };

    /**
     * Check if all fields are valid then proceed to create indisponibilita
     */
    function checkFields(indisponibilita) {
      if (
        moment(indisponibilita.data_inizio).valueOf() <=
        moment(indisponibilita.data_fine).valueOf()
      ) {
        validDate.value = true;
      } else {
        validDate.value = false;
      }
      if (
        moment(indisponibilita.ora_inizio).valueOf() <
        moment(indisponibilita.ora_fine).valueOf()
      ) {
        validHour.value = true;
      } else {
        validHour.value = false;
      }

      /*       console.log(
        "validDate: " + validDate.value + "  ---  validHour: " + validHour.value
      ); */

      return validDate.value && validHour.value ? true : false;
    }

    /**
     * Create new indisponibilita
     */
    function creaIndisponibilita() {
      const data_inizio_formatted = moment(indisponibilita.data_inizio).format(
        "YYYY-MM-DD"
      );
      const data_fine_formatted = moment(indisponibilita.data_fine).format(
        "YYYY-MM-DD"
      );

      indisponibilita.user = userID; //set user as logged user id

      if (
        !indisponibilita.data_inizio ||
        !indisponibilita.data_fine ||
        /*!indisponibilita.tipo ||*/
        indisponibilita.turni.length === 0
      ) {
        openToast("La tipologia ed i turni sono obbligatori", "danger");
        return;
      }

      const data = new FormData();
      data.append("indisponibilita_user", indisponibilita.user); //logged user
      data.append("indisponibilita_data_inizio", data_inizio_formatted);
      data.append("indisponibilita_data_fine", data_fine_formatted);
      data.append("indisponibilita_tipo", indisponibilita.tipo);
      data.append("indisponibilita_recurrency", "1"); //creazione ricorrenze

      /* Turni selezionati */
      indisponibilita.turni.forEach((turno) => {
        data.append("indisponibilita_turni[]", turno);
      });

      // Display the key/value pairs
      /* for (const pair of data.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      } */

      apiIndisponibilita
        .saveIndisponibilita(data)
        .then((response) => {
          if (response.data.status == 8) {
            openToast(response.data.message, "danger");
          } else if (response.data.status == 0) {
            const res = response.data.data[0];
            successResponse.value = true;
            closeModalOnSubmit(successResponse, res);
          }
        })
        .catch((error) => {
          // handle error
          console.error(error);
          openToast(
            "Errore durante la creazione della indisponibilita",
            "danger"
          );
        })
        .finally(() => {
          //console.log("fine creazione indisponibilita");
        });
    }

    return {
      indisponibilita,
      creaIndisponibilita,
      closeModal,
      customersDB,
      userID,
    };
  },
});
</script>

<style scoped>
.error_message {
  color: #eb445a;
}
.danger {
  color: #eb445a !important;
}

.example {
  border: 1px solid black;
}

.alert-wrapper {
  width: 90% !important;
}

.helper_text {
  font-size: 14px;
}

.field {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.field_content ion-select {
  max-width: 100% !important;
}
</style>

import axios from 'axios';
const TOKEN = 'Bearer 26d460b39301781b10d37af31e2e5a4a';

export default {

    /**
     * 
     * @param userID id of the logged user
     * @returns array with all indisponibilita of the logged user
     * 
     * Salva intervento appena creato
     */
    async getIndisponibilita(userID: string) {
        const data = new FormData();
        data.append("offset", "0");
        data.append("where[indisponibilita_user]", userID.toString());
        data.append("orderby", "indisponibilita_creation_date");
        data.append("orderdir", "desc");

        const response = await axios.post('search/indisponibilita', data, {
            headers: {
                Authorization: TOKEN,
            },
        });
        localStorage.setItem('indisponibilita', JSON.stringify(response.data.data));
        //console.log(response);
        return response.data.data;
    },

    /*     async getPresenzaDetail(id: string) {
            const data = new FormData();
            data.append("where[presenze_id]", id.toString());
    
            const response = await axios.post('search/presenze', data, {
                headers: {
                    Authorization: TOKEN,
                },
            });
            //console.log(response);
            return response.data.data[0];
        }, */

    /**
     * 
     * @param indisponibilita array with indisponibilita data
     * @returns indisponibilita just created
     * 
     * Salva indisponibilita appena creata
     */
    async saveIndisponibilita(indisponibilita) {
        const response = await axios.post(`create/indisponibilita`, indisponibilita, {
            headers: {
                Authorization: TOKEN,
            },
        });
        //console.log(response);
        return response;
    },
}
<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>Indisponibilità</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Indisponibilità</ion-title>
        </ion-toolbar>
      </ion-header>

      <div v-if="loading" class="loading_spinner">
        <ion-spinner name="crescent" color="primary"></ion-spinner>
      </div>

      <div v-else>
        <div class="ion-padding-horizontal ion-margin-top">
          <ion-button
            color="success"
            fill="solid"
            expand="block"
            @click="openModal"
          >
            Crea indisponibilità
          </ion-button>
        </div>
        <div
          v-if="indisponibilita.length == 0"
          class="ion-text-center loading_spinner"
        >
          <ion-text color="danger">
            <h2>Non hai inserito alcuna indisponibilità</h2>
          </ion-text>
        </div>

        <div class="ion-padding-horizontal ion-text-center" v-else>
          <div>
            <ion-text color="primary">
              <h5>Le indisponibilità da te comunicate</h5>
            </ion-text>
          </div>

          <div class="calendar_container">
            <Calendar
              :value="date"
              is-expanded
              :attributes="setAttributes"
              @dayclick="dayClicked"
              ref="refCalendar"
            >
            </Calendar>

            <!-- Display today indisponibilita -->
            <div v-if="flagTodayIndisponibilita">
              <h3 class="ion-text-center">
                {{
                  date.toLocaleDateString("it-IT", {
                    weekday: "long",
                    day: "numeric",
                  })
                }}
              </h3>
              <div v-if="todayIndisponibilita.length != 0">
                <ion-list>
                  <ion-item
                    v-for="indisponibilita in todayIndisponibilita"
                    :key="indisponibilita.indisponibilita_id"
                    class="ion-no-padding"
                  >
                    <ion-label>
                      <ion-text
                        :color="
                          indisponibilita.indisponibilita_tipo == 1
                            ? 'primary'
                            : 'danger'
                        "
                        >>
                        <h3>
                          <!--                           {{
                            printDate(
                              indisponibilita.data_inizio,
                              indisponibilita.data_fine
                            )
                          }} -  -->{{
                            indisponibilita.indisponibilita_tipo_value
                          }}
                        </h3>
                      </ion-text>
                      <ion-text color="dark">
                        <span style="margin-right: 8px">Turno:</span>
                        <span
                          style="margin-right: 8px"
                          v-for="(
                            turno, index
                          ) in indisponibilita.indisponibilita_turni"
                          :key="index"
                        >
                          {{ turno }}
                        </span>
                      </ion-text>
                    </ion-label>
                  </ion-item>
                </ion-list>
              </div>
              <div v-else>
                <ion-text color="danger">
                  <div class="ion-text-center ion-margin-top font-bold">
                    Non hai comunicato indisponibilità per oggi
                  </div>
                </ion-text>
              </div>
            </div>

            <!-- Add a column to display info for clicked day -->
            <div v-if="selectedDay" class="selected-day">
              <h3 class="ion-text-center">
                {{
                  selectedDay.date.toLocaleDateString("it-IT", {
                    weekday: "long",
                    day: "numeric",
                  })
                }}
              </h3>
              <div v-if="selectedDay.attributes != 0">
                <ion-list>
                  <ion-item
                    v-for="attr in selectedDay.attributes"
                    :key="attr.key"
                    class="ion-no-padding"
                  >
                    <ion-label>
                      <ion-text
                        :color="
                          attr.customData.indisponibilita_tipo == 1
                            ? 'primary'
                            : 'danger'
                        "
                      >
                        <h3 class="riferimento_indisp">
                          <!--                           {{
                            printDate(
                              attr.customData.indisponibilita_data_inizio,
                              attr.customData.indisponibilita_data_fine
                            )
                          }}
                          - -->
                          {{ attr.customData.indisponibilita_tipo_value }}
                        </h3>
                      </ion-text>
                      <ion-text color="dark">
                        <span style="margin-right: 8px">Turno:</span>
                        <span
                          style="margin-right: 8px"
                          v-for="(turno, index) in attr.customData
                            .indisponibilita_turni"
                          :key="index"
                        >
                          {{ turno }}
                        </span>
                      </ion-text>
                    </ion-label>
                  </ion-item>
                </ion-list>
              </div>

              <div v-else>
                <ion-text color="danger">
                  <div class="ion-text-center ion-margin-top font-bold">
                    Non hai comunicato indisponibilità nella giornata
                    selezionata
                  </div>
                </ion-text>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ion-fab horizontal="end" vertical="bottom" slot="fixed">
        <ion-fab-button color="success" @click="getIndisponibilita()">
          <ion-icon :icon="refresh"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonSpinner,
  IonButton,
  IonText,
  IonLabel,
  IonList,
  IonItem,
  IonIcon,
  IonFab,
  IonFabButton,
  modalController,
} from "@ionic/vue";
import { refresh } from "ionicons/icons";

import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import moment from "moment";

import { openToast } from "../services/toast";

import apiIndisponibilita from "../services/indisponibilita";

import { Calendar } from "v-calendar";
import ModalIndisponibilita from "../components/ModalIndisponibilita.vue";

export default {
  name: "ElencoPresenze",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonSpinner,
    IonButton,
    IonText,
    IonLabel,
    IonList,
    IonItem,
    IonIcon,
    IonFab,
    IonFabButton,
    Calendar,
  },
  setup() {
    const router = useRouter();
    const loading = ref(false);
    const presenze = ref([]);
    const userID = JSON.parse(localStorage.getItem("userInfo")).users_id;

    const indisponibilita = ref([]);

    const date = ref(new Date());
    const selectedDay = ref(null); // Add state to store selected day

    const refCalendar = ref(null);

    const todayIndisponibilita = ref([]);
    const flagTodayIndisponibilita = ref(true); //false when clicked on calendar to hide today indisponibilita

    /**
     * Set correct date label for each indisponibilità based on data_inizio and data_fine
     *
     */
    function printDate(inizio: string, fine: string) {
      const dataInizio = moment(inizio).format("DD/MM/YYYY");
      const dataFine = moment(fine).format("DD/MM/YYYY");

      return dataInizio === dataFine
        ? dataInizio
        : `Dal ${dataInizio} al ${dataFine}`;
    }

    /**
     * Convert indisponibilita array into attributes for calendar visualization
     * Assign key derived from original indisponibilita.indisponibilita_id field
     * Assign indisponibilita element to attribute's customData property to access original indisponibilita in template
     * Assign indisponibilita_data_inizio as date to use in calendar visualization
     */
    const setAttributes = computed(() => {
      return indisponibilita.value.map((indisponibilita) => ({
        key: `indisponibilita.${indisponibilita.indisponibilita_id}`,
        highlight: {
          start: {
            color: "gray",
            fillMode: "solid",
          },
          base: {
            color: "gray",
            fillMode: "light",
          },
          end: {
            color: "gray",
            fillMode: "solid",
          },
        },
        dates: {
          start: indisponibilita.indisponibilita_data_inizio,
          end: indisponibilita.indisponibilita_data_fine,
        },
        customData: indisponibilita,
      }));
    });

    /**
     * Handle click on calendat day
     */
    const dayClicked = (day: any) => {
      flagTodayIndisponibilita.value = false; //hide today indisp. load on first time when clicked on Indisponibilità tab
      selectedDay.value = day;
    };

    /**
     * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
     */
    function dateFormat(date: string) {
      if (date && moment.isDate(new Date(date))) {
        return moment(date).format("DD/MM/YYYY");
      } else {
        return "-";
      }
    }

    /**
     * Get only todays indisponibilita to display when tab 2 is loading
     * Hide when click on calendar date
     */
    function getTodayIndisponibilita(indisponibilita) {
      indisponibilita.forEach((indisp) => {
        if (moment.isDate(new Date(indisp.indisponibilita_data_inizio))) {
          const todayDate = moment(date.value).format("DD/MM/YYYY");
          const indispDate = moment(indisp.indisponibilita_data_inizio).format(
            "DD/MM/YYYY"
          );
          if (indispDate === todayDate) {
            todayIndisponibilita.value.push(indisp);
          }
        }
      });
    }

    /**
     * Get all Indisponibilità for this user
     */
    function getIndisponibilita() {
      loading.value = true;

      apiIndisponibilita
        .getIndisponibilita(userID)
        .then((response) => {
          //console.log(response);
          indisponibilita.value = response;
          selectedDay.value = null;
          // FIX PER MOSTRATE INDISPONIBILITA DI OGGI --> Mi prendo le indisp di oggi e le mostro, al click su un giorno del calendario li nascondo  e mostro quellie "vere" (della giornata cliccata) sul calendario
          todayIndisponibilita.value.length = 0;
          if (todayIndisponibilita.value.length === 0) {
            getTodayIndisponibilita(indisponibilita.value);
          }
        })
        .catch((error) => {
          console.error(error);
          openToast(
            "Errore durante la richiesta delle indisponibiltia",
            "danger"
          );
        })
        .finally(() => {
          loading.value = false;
          flagTodayIndisponibilita.value = true;
          //console.log("fine richiesta indisponibilita");
        });
    }

    /**
     * Open modal to add new indisponibilità
     */
    async function openModal() {
      const modal = await modalController.create({
        component: ModalIndisponibilita,
      });
      modal.onDidDismiss().then((detail) => {
        if (detail.data != undefined) {
          //indisponibilita.value = [detail.data, ...indisponibilita.value];
          getIndisponibilita(); //richiede indisp visto che il PP ne crea tante quanti sono i giornis scelti
          openToast("Indisponiiblità inserita correttamente", "success");
        }
      });
      return modal.present();
    }

    onMounted(() => {
      getIndisponibilita();
    });

    return {
      loading,
      presenze,
      dateFormat,
      refresh,
      getIndisponibilita,
      indisponibilita,
      openModal,
      //CALENDAR FIELD
      refCalendar,
      date,
      setAttributes,
      dayClicked,
      selectedDay,
      //flag per indisponibilita di oggi, se true mostro
      flagTodayIndisponibilita,
      todayIndisponibilita,
      printDate,
    };
  },
};
</script>

<style scoped>
.loading_spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.base_text {
  font-size: 14px;
}
.pb-0 {
  padding-bottom: 0;
}

ion-item {
  font-size: 14px;
}
ion-label {
  margin: 0;
}

.font-bold {
  font-weight: 600;
}

.riferimento_indisp {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}
</style>